import { configureStore } from '@reduxjs/toolkit';
import feesReducer from '../features/fees/feesSlice';
import forecastReducer from '../features/forecast/forecastSlice';
import interestReducer from '../features/interest/interestSlice';
export var store = configureStore({
    reducer: {
        fees: feesReducer,
        forecast: forecastReducer,
        interest: interestReducer,
    },
});
